<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.732 0.928418C8.09467 1.18026 8.17867 1.67023 7.91962 2.02281L3.88467 7.51449C3.73716 7.71525 3.50109 7.83686 3.24738 7.84279C2.99366 7.84872 2.75186 7.73827 2.5946 7.54462L0.173631 4.56342C-0.102538 4.22335 -0.0428367 3.73001 0.306976 3.46153C0.65679 3.19305 1.16425 3.25109 1.44042 3.59117L3.19572 5.75266L6.60627 1.11082C6.86532 0.758241 7.36933 0.676579 7.732 0.928418Z"
    />
  </svg>
</template>
