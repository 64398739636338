<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4966 7.25104C15.1201 3.46965 11.9226 0.516602 8.03354 0.516602C5.58023 0.516602 3.40143 1.69149 2.03339 3.50996V2.38913C2.03339 1.9749 1.6976 1.63911 1.28338 1.63911C0.869153 1.63911 0.533358 1.97491 0.533358 2.38913V5.29766C0.533358 5.75687 0.905627 6.12914 1.36484 6.12914H4.28513C4.69842 6.12914 5.03347 5.7941 5.03347 5.3808C5.03347 4.96751 4.69842 4.63247 4.28513 4.63247H3.07217C4.15145 3.05123 5.97099 2.01328 8.03354 2.01328C11.0931 2.01328 13.618 4.29854 13.9872 7.25148C14.0386 7.6625 14.3695 7.99999 14.7837 7.99999C15.1979 7.99999 15.5377 7.66323 15.4966 7.25104Z"
      fill=""
    />
    <path
      d="M0.503368 8.74894C0.879933 12.5303 4.07745 15.4834 7.96646 15.4834C10.4198 15.4834 12.5986 14.3085 13.9666 12.49V13.6109C13.9666 14.0251 14.3024 14.3609 14.7166 14.3609C15.1308 14.3609 15.4666 14.0251 15.4666 13.6109V10.7023C15.4666 10.2431 15.0944 9.87084 14.6352 9.87084H11.7149C11.3016 9.87084 10.9665 10.2059 10.9665 10.6192C10.9665 11.0325 11.3016 11.3675 11.7149 11.3675H12.9278C11.8486 12.9488 10.029 13.9867 7.96646 13.9867C4.90694 13.9867 2.38197 11.7014 2.01277 8.7485C1.96138 8.33748 1.63052 7.99999 1.2163 7.99999C0.802073 7.99999 0.462321 8.33676 0.503368 8.74894Z"
      fill=""
    />
  </svg>
</template>
