<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.39543 14.7581L13.9291 8.76356C14.0297 8.70627 14.1241 8.63442 14.1819 8.534C14.3665 8.21285 14.3665 7.78972 14.1819 7.46857C14.1241 7.36815 14.0297 7.29631 13.9291 7.23902L3.39543 1.24447C2.88083 0.959444 2.54363 0.879989 2.23997 1.24447C1.92118 1.61777 1.92118 2.22253 2.23997 2.59583L5.50389 8.00129L2.23997 13.4067C1.92118 13.78 1.92118 14.3848 2.23997 14.7581C2.54551 15.1199 2.88083 15.0388 3.39543 14.7581Z"
    />
  </svg>
</template>
