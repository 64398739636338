<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.03158 2.16699C7.42384 2.16768 11.372 2.18136 12.3946 2.45509C12.9891 2.61288 13.4565 3.07938 13.616 3.67623C13.8931 4.71023 13.9041 6.81904 13.9046 6.98609V7.00723C13.9041 7.17478 13.8931 9.28992 13.616 10.3239C13.4565 10.9208 12.9891 11.3873 12.3946 11.5451C11.3397 11.8274 7.17157 11.8331 7.00526 11.8332H6.99502C6.82869 11.8331 2.65986 11.8274 1.60431 11.5451C1.01053 11.3873 0.542403 10.9208 0.383601 10.3239C0.107201 9.28992 0.0961454 7.17478 0.0957031 7.00723V6.98609C0.0961454 6.81904 0.107201 4.71023 0.383601 3.67623C0.542403 3.07938 1.01053 2.61288 1.60431 2.45509C2.62755 2.18136 6.57637 2.16768 6.96869 2.16699H7.03158ZM5.58749 4.95912V9.04103L9.19714 6.99664L5.58749 4.95912Z"
    />
  </svg>
</template>
