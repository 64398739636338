<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3002 4.51557C14.3002 4.82682 14.1852 5.13807 13.9545 5.37556L8.4891 11L13.9545 16.6243C14.4152 17.0993 14.4152 17.8693 13.9545 18.3443C13.493 18.8185 12.7448 18.8185 12.2834 18.3443L5.98322 11.8599C5.52252 11.385 5.52252 10.6149 5.98322 10.14L12.2834 3.65558C12.7448 3.18141 13.493 3.18141 13.9545 3.65558C14.1852 3.89307 14.3002 4.20432 14.3002 4.51557Z"
      fill="#F5F5FA"
    />
  </svg>
</template>
