<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Chat action button">
      <path
        id="Subtract"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.55786 9.72638C5.34857 9.44733 4.9527 9.39078 4.67365 9.60007C4.3946 9.80935 4.33804 10.2052 4.54733 10.4843C5.03643 11.1364 5.9625 11.9241 7.18705 12.1573C8.45769 12.3994 9.92467 12.0212 11.3939 10.5519C11.6406 10.3053 11.6406 9.90538 11.3939 9.65874C11.1473 9.41209 10.7474 9.41209 10.5007 9.65874C9.27526 10.8842 8.21592 11.0674 7.4234 10.9165C6.5848 10.7568 5.91087 10.1971 5.55786 9.72638ZM5.89467 4.42102C6.24348 4.42102 6.52625 4.70378 6.52625 5.05259V6.7368C6.52625 7.08562 6.24348 7.36838 5.89467 7.36838C5.54586 7.36838 5.26309 7.08562 5.26309 6.7368V5.05259C5.26309 4.70378 5.54586 4.42102 5.89467 4.42102ZM10.7369 5.05259C10.7369 4.70378 10.4541 4.42102 10.1053 4.42102C9.75652 4.42102 9.47375 4.70378 9.47375 5.05259V6.7368C9.47375 7.08562 9.75652 7.36838 10.1053 7.36838C10.4541 7.36838 10.7369 7.08562 10.7369 6.7368V5.05259Z"
      />
    </g>
  </svg>
</template>
