<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.77897 17.8126C6.0295 17.7401 6.29738 17.7706 6.53251 17.8834C7.7994 18.4915 9.18866 18.8073 10.5974 18.8052C16.239 18.7967 20 14.5957 20 9.40261C20 4.20955 15.7905 0 10.5974 0C5.40434 0 1.19479 4.20955 1.19479 9.40261C1.19267 10.8114 1.5085 12.2006 2.1166 13.4675C2.22946 13.7026 2.25996 13.9705 2.18745 14.2211L0.976884 18.4038C0.867403 18.7821 1.2179 19.1326 1.59618 19.0231L5.77897 17.8126ZM6.49421 11.3515C7.34396 11.3515 8.03282 10.6627 8.03282 9.81291C8.03282 8.96316 7.34396 8.2743 6.49421 8.2743C5.64446 8.2743 4.9556 8.96316 4.9556 9.81291C4.9556 10.6627 5.64446 11.3515 6.49421 11.3515ZM12.1358 9.81291C12.1358 10.6627 11.4469 11.3515 10.5972 11.3515C9.74742 11.3515 9.05856 10.6627 9.05856 9.81291C9.05856 8.96316 9.74742 8.2743 10.5972 8.2743C11.4469 8.2743 12.1358 8.96316 12.1358 9.81291ZM14.7001 11.3515C15.5499 11.3515 16.2387 10.6627 16.2387 9.81291C16.2387 8.96316 15.5499 8.2743 14.7001 8.2743C13.8504 8.2743 13.1615 8.96316 13.1615 9.81291C13.1615 10.6627 13.8504 11.3515 14.7001 11.3515Z"
    />
  </svg>
</template>
