<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#54C790"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.1 8C14.1 11.3689 11.3689 14.1 8 14.1C4.63106 14.1 1.9 11.3689 1.9 8C1.9 4.63106 4.63106 1.9 8 1.9C11.3689 1.9 14.1 4.63106 14.1 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM11.8509 5.2299C12.1077 4.88036 12.0245 4.3946 11.6649 4.14493C11.3054 3.89526 10.8058 3.97622 10.549 4.32576L7.168 8.92764L5.42792 6.78476C5.15415 6.44761 4.65109 6.39007 4.30431 6.65624C3.95753 6.92241 3.89835 7.41149 4.17212 7.74864L6.5721 10.7042C6.72799 10.8962 6.96769 11.0056 7.21921 10.9998C7.47072 10.9939 7.70474 10.8733 7.85097 10.6743L11.8509 5.2299Z"
    />
  </svg>
</template>
