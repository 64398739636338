<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.92024 0.386028C5.86867 0.419106 4.53452 1.69467 2.95547 3.22063C1.07671 5.03624 0.0694112 6.03104 0.0408115 6.09906C-0.0215138 6.24751 -0.0124495 6.38946 0.0679734 6.52333C0.106388 6.58722 1.43597 7.89142 3.02261 9.42151C6.15091 12.4383 6.00304 12.3103 6.2756 12.2373C6.34214 12.2195 6.43385 12.1758 6.47939 12.1401C6.65721 12.001 6.65477 12.0251 6.65477 10.4151V8.93286L7.53776 8.94952C8.21628 8.96233 8.52115 8.98154 8.85425 9.03253C11.3642 9.41666 13.5508 10.8534 14.8445 12.9684C14.9559 13.1506 15.0804 13.3407 15.1212 13.3908C15.334 13.6529 15.7057 13.6323 15.9185 13.3467C16.1243 13.0703 15.9189 11.3471 15.5434 10.1999C14.4952 6.99703 11.8368 4.58414 8.56141 3.86261C8.12026 3.76543 7.6371 3.69395 7.26427 3.6707C7.10955 3.66106 6.9091 3.64577 6.81886 3.63669L6.65477 3.62021V2.1582C6.65477 0.544704 6.65608 0.5563 6.4576 0.415749C6.32254 0.320089 6.0468 0.304825 5.92024 0.386028Z"
    />
  </svg>
</template>
