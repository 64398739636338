<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.03987 0.0247819C4.81339 0.46256 1.30118 3.49719 0.279534 7.5939C0.0528849 8.50275 0 8.95794 0 10.0003C0 10.693 0.0176153 11.0357 0.0714396 11.3894C0.637437 15.1085 3.09837 18.1185 6.65203 19.4382C7.11773 19.6112 8.07142 19.844 8.64842 19.9255C9.3526 20.025 10.6473 20.0248 11.3494 19.9252C15.4058 19.3493 18.7301 16.3629 19.7186 12.4068C19.9326 11.5501 20 10.974 20 10.0003C20 9.02665 19.9326 8.45059 19.7186 7.5939C18.822 4.00555 15.9724 1.16356 12.3868 0.281548C12.0746 0.204776 11.6342 0.114153 11.4081 0.0801889C10.9146 0.00607813 9.53251 -0.0262426 9.03987 0.0247819ZM11.2907 2.48272C12.4727 2.68874 13.6282 3.1892 14.5985 3.91528C15.0076 4.22139 15.7528 4.96477 16.0644 5.37758C16.8035 6.35683 17.302 7.49529 17.523 8.70908C17.635 9.32411 17.6429 10.6142 17.5382 11.1938C17.3836 12.0493 17.176 12.6816 16.8095 13.4135C16.6026 13.8267 16.2065 14.4611 16.1554 14.4611C16.1159 14.4611 5.53639 3.88597 5.53639 3.84645C5.53639 3.80654 6.10521 3.44076 6.47587 3.24234C7.17058 2.87045 8.17684 2.54611 8.92572 2.45263C9.11769 2.42869 9.31878 2.40145 9.3726 2.39214C9.61902 2.34949 10.8636 2.40826 11.2907 2.48272ZM9.16705 10.8318C12.079 13.7425 14.4615 16.1376 14.4615 16.1542C14.4615 16.2078 13.8145 16.6081 13.378 16.8246C11.8381 17.5885 10.1465 17.8125 8.48331 17.4728C5.73842 16.9122 3.50845 14.8794 2.69455 12.1959C2.45772 11.415 2.39352 10.9551 2.39094 10.0199C2.38781 8.90308 2.51788 8.18811 2.8999 7.22217C3.13931 6.61676 3.74422 5.53961 3.84478 5.53961C3.86009 5.53961 6.2551 7.9211 9.16705 10.8318Z"
    />
  </svg>
</template>
