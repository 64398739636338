<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3471 10.434L12.2811 10.2358C12.083 9.57547 12.4131 8.78302 13.0075 8.51887L13.2056 8.38679C14.2622 7.79245 14.2622 6.27358 13.2056 5.67925L13.0735 5.54717C12.4131 5.21698 12.149 4.49057 12.3471 3.76415L12.4131 3.56604C12.7433 2.37736 11.6867 1.32075 10.498 1.65094L10.2999 1.71698C9.63956 1.91509 8.8471 1.65094 8.51691 0.990566L8.38484 0.792453C7.7905 -0.264151 6.27163 -0.264151 5.67729 0.792453L5.54522 0.990566C5.21503 1.65094 4.48861 1.98113 3.7622 1.71698L3.56408 1.65094C2.44144 1.32075 1.3188 2.4434 1.64899 3.56604L1.71503 3.69811C1.91314 4.35849 1.64899 5.15094 0.988613 5.48113L0.7905 5.61321C-0.266104 6.20755 -0.266104 7.72642 0.7905 8.32076L0.988613 8.45283C1.64899 8.78302 1.91314 9.50943 1.71503 10.2358L1.64899 10.434C1.3188 11.6226 2.37541 12.6792 3.56408 12.3491L3.7622 12.283C4.42257 12.0849 5.21503 12.3491 5.54522 13.0094L5.67729 13.2075C6.27163 14.2642 7.7905 14.2642 8.38484 13.2075L8.45088 13.0094C8.78106 12.3491 9.50748 12.0849 10.2339 12.283L10.432 12.3491C11.6207 12.6792 12.6773 11.6226 12.3471 10.434ZM9.80762 5.435C9.97721 5.20417 9.92222 4.88339 9.68479 4.71851C9.44737 4.55364 9.11742 4.6071 8.94784 4.83793L6.71513 7.87691L5.56601 6.46179C5.38522 6.23915 5.05301 6.20115 4.82401 6.37692C4.595 6.55269 4.55592 6.87568 4.73671 7.09832L6.3216 9.05008C6.42455 9.17686 6.58285 9.24918 6.74894 9.24529C6.91503 9.24142 7.06957 9.1618 7.16614 9.03036L9.80762 5.435Z"
      fill="#4282FF"
    />
  </svg>
</template>
